<template>
    <div>
        <div class="menu_title">
            <div class="el-icon-s-flag descrip">&nbsp;操作提示：</div>
            <div class="descrip_content">
                <p>1、订单设置管理案例订单退单处理的关键流程，请谨慎操作，所有设置在点击保存后生效；</p>
                <p>2、客户逾期未处理的待收货订单，将会自动确认收货；</p>
                <p>3、超过设定时间的已完成订单，客户将无法发起退货退款申请；</p>
                <p>4、商家逾期未处理的待审核退单，将会自动审核通过；</p>
                <p>5、商家逾期未处理的待收货订单，将会自动确认收货。</p>
            </div>
            
        </div>
        <div style="height: 12px; background: #f6f7f9"></div>
        <div class="setting_content">
            <div class="setting_title" style="border-bottom: 1px solid #EFEFEF;">
                订单设置
            </div>
            <div class="setting_content_content">
                <el-form ref="formInline" :model="formInline" label-width="180px">
                    <el-form-item v-for="(item,index) in formInline" :key="index" v-if="item.installType == 1" label="订单支付顺序：">
                        <el-radio v-model="item.installValue" label="1">先款后货</el-radio>
                        <el-radio v-model="item.installValue" label="2">不限</el-radio>

                        <p style="color: rgba(29, 30, 31, 0.5)">选择“先款后货”，客户必须支付订单后商家才可发货；选择“不限”，无论客户是否支付都可发货。</p>
                    </el-form-item>
                    <el-form-item label="订单失效时间：" v-for="(item,index) in formInline" :key="index" v-if="item.installType == 2">
                        订单提交<el-input class="ml12 mr12" style="width: 100px" v-model="item.installValue" placeholder="请输入"></el-input>小时后，客户逾期未支付，将会自动作废订单。
                        <p style="color: rgba(29, 30, 31, 0.5)">数据不填写，默认订单失效时间为关闭状态。</p>
                    </el-form-item>
                    <el-form-item label="订单自动确认收货：" v-for="(item,index) in formInline" :key="index" v-if="item.installType == 3">
                        订单全部发货<el-input class="ml12 mr12" style="width: 100px" v-model="item.installValue" placeholder="请输入"></el-input>天后，客户逾期未处理的待收货订单，将会自动确认收货。
                        <p style="color: rgba(29, 30, 31, 0.5)">数据不填写，默认订单自动确认收货功能为关闭状态。</p>
                    </el-form-item>
                    <el-form-item label="已完成订单允许申请退单：" v-for="(item,index) in formInline" :key="index" v-if="item.installType == 4">
                        订单完成<el-input class="ml12 mr12" style="width: 100px" v-model="item.installValue" placeholder="请输入"></el-input>天后，允许客户发起退货退款申请，未发货订单随时可退。
                        <p style="color: rgba(29, 30, 31, 0.5)">数据不填写，默认该功能为关闭状态。</p>
                    </el-form-item>
                    <el-form-item label="待审核退单自动审核：" v-for="(item,index) in formInline" :key="index" v-if="item.installType == 5">
                        退单提交<el-input class="ml12 mr12" style="width: 100px" v-model="item.installValue" placeholder="请输入"></el-input>天后，商家逾期未处理的待审核退单，将会自动审核通过。
                        <p style="color: rgba(29, 30, 31, 0.5)">数据不填写，默认该功能为关闭状态。</p>
                    </el-form-item>
                    <el-form-item label="退单自动确认收货：" v-for="(item,index) in formInline" :key="index" v-if="item.installType == 6">
                        退单物流信息提交<el-input class="ml12 mr12" style="width: 100px" v-model="item.installValue" placeholder="请输入"></el-input>天后，商家逾期未处理的待收货退单，将会自动确认收货，非快递退回的退单，在审核通过后开始计时。
                        <p style="color: rgba(29, 30, 31, 0.5)">数据不填写，默认该功能为关闭状态。</p>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="bootom">
            <el-button type="primary" @click="save" class="pt6 pb6">保存</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formInline: []
        }
    },
    mounted(){
        this.search();
    },
    methods: {
        search(){
            this.qa.orderSetting({}).then(res => {
                this.formInline = res.data;
            })
        },
        save(){
            console.log(this.formInline)
            this.qa.orderSettingAdd(this.formInline).then(res => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '订单设置成功！',
                        type: 'success'
                    });
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style scoped>
.menu_title {
    width: 100%;
    height: 146px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .setting_content {
      /* padding: 24px; */
      background: #fff;
  }
  .setting_title {
      padding: 24px;
      padding-bottom: 6px;
  }
  .setting_content_content {
      padding: 24px;
  }
  .bootom {
      width: calc(100% - 254px);
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 230px;
      background: #fff;
      margin-left: -24px;
      text-align: center;
      line-height: 44px;
  }
</style>